import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getBlogBySlug } from '../utilities/Blog';
import { ReactComponent as ViewsIcon } from '../assets/icons/viewsIcon.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg';
import { ReactComponent as InstaIcon } from '../assets/icons/instagram.svg';
import Breadcrumb from '../components/common/Breadcrumb';
import Icon from '../components/common/Icon';
import Newsletter from '../components/Newsletter';
import { BlogCard } from './Blog';

const LoadingPlaceholder = () => (
    <div className="w-full animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
        <div className="h-4 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="h-64 bg-gray-200 rounded w-full mb-8"></div>
        <div className="space-y-3">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>
    </div>
);

const BlogDetails = () => {
    const { subject, slug } = useParams();
    const navigate = useNavigate();
    const [blog, setBlog] = useState(null);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingRelated, setLoadingRelated] = useState(true);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const loadBlog = async () => {
            if (!subject || !slug) {
                setError('Invalid blog URL');
                setLoading(false);
                return;
            }

            try {
                const blogPost = await getBlogBySlug(subject, slug);
                if (!blogPost) {
                    setError('Blog post not found');
                    navigate('/blog', { replace: true });
                    return;
                }
                setBlog(blogPost);
                setLoadingRelated(true);
                const relatedPostsData = await Promise.all(
                    blogPost.relatedPosts.map(async (related) => {
                        try {
                            return await getBlogBySlug(related.subject, related.slug);
                        } catch (err) {
                            console.error(`Failed to load related post: ${related.slug}`, err);
                            return null;
                        }
                    })
                );

                setRelatedPosts(relatedPostsData.filter(post => post !== null));
            } catch (err) {
                setError('Failed to load blog post');
                console.error(err);
            } finally {
                setLoading(false);
                setLoadingRelated(false);
            }
        };

        loadBlog();
    }, [subject, slug, navigate]);

    const submitComment = async (blogId, commentData) => {
        try {
            // In a real application, this would be an API call
            const newComment = {
                name: commentData.name || 'Anonymous',
                profileImage: 'default-user',
                comment: commentData.comment,
                date: new Date().toISOString()
            };

            // Here you would typically send this to your backend
            console.log('Submitting comment:', newComment);

            return newComment;
        } catch (error) {
            console.error('Error submitting comment:', error);
            throw error;
        }
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!comment.trim()) return;

        setSubmitting(true);
        try {
            // Add your comment submission logic here
            await submitComment(blog.id, comment);
            setComment('');
            // Optionally reload comments
        } catch (err) {
            console.error('Failed to submit comment:', err);
        } finally {
            setSubmitting(false);
        }
    };

    const handleShare = (platform) => {
        const url = window.location.href;
        const title = blog?.title;

        const shareUrls = {
            facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
            twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
            linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`,
            email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`
        };

        window.open(shareUrls[platform], '_blank', 'width=600,height=400');
    };

    if (loading) return <LoadingPlaceholder />;
    if (error) return <div className="text-center py-10">{error}</div>;
    if (!blog) return null;

    return (
        <section className='w-full min-h-[100vh] my-10 flex flex-col items-center'>
            <Breadcrumb
                address={`Blog/${subject?.charAt(0).toUpperCase() + subject?.slice(1)}/${blog.title}`}
            />

            <article className='w-[90%] lg:w-[80%] flex flex-col my-5 items-center py-5 px-3'>
                <h1 className='font-bold text-4xl text-center'>{blog.title}</h1>

                <div className='w-full flex flex-col lg:flex-row gap-4 justify-between mt-16 mb-10'>
                    <div className='flex gap-5'>
                        <time dateTime={blog.date}>{blog.date}</time>
                        <span className='flex items-center gap-2'>
                            <ViewsIcon />
                            <span>{blog.views.toLocaleString()} views</span>
                        </span>
                    </div>

                    <div className='flex items-center'>
                        <span>Share with friends:</span>
                        <div className="flex gap-2 mx-3">
                            <button
                                onClick={() => handleShare('facebook')}
                                aria-label="Share on Facebook"
                                className="hover:opacity-80"
                            >
                                <FacebookIcon />
                            </button>
                            <button
                                onClick={() => handleShare('twitter')}
                                aria-label="Share on Twitter"
                                className="hover:opacity-80"
                            >
                                <TwitterIcon />
                            </button>
                            <button
                                onClick={() => handleShare('instagram')}
                                aria-label="Share on Instagram"
                                className="hover:opacity-80"
                            >
                                <InstaIcon />
                            </button>
                        </div>
                    </div>
                </div>

                <Icon
                    name={blog.heroImage}
                    className='w-full aspect-auto mt-5 mb-16'
                    png
                />

                <div className='flex justify-between w-full'>
                    <div className='flex flex-col lg:w-[50%] space-y-10'>
                        {blog.content.map((section, index) => (
                            <div key={index} className='space-y-3'>
                                {section.type === 'heading' && (
                                    <h2 className='font-bold text-xl'>{section.content}</h2>
                                )}
                                {section.type === 'paragraph' && (
                                    <p className='text-lg leading-relaxed'>{section.content}</p>
                                )}
                            </div>
                        ))}
                        <div className='pt-8 border-t'>
                            <h5 className='font-bold text-2xl'>Author - {blog.author}</h5>
                        </div>
                    </div>
                    <div className='hidden lg:flex flex-col gap-3 items-center'>
                        <h2 className='text-xl font-bold'>Recommended posts for you</h2>
                        {loadingRelated ? (
                            <div className="animate-pulse space-y-4">
                                {[1, 2, 3].map((index) => (
                                    <div
                                        key={index}
                                        className='lg:w-[26.5vw] h-[75vh] border-2 rounded-md bg-gray-200'
                                    />
                                ))}
                            </div>
                        ) : (
                            relatedPosts.map((post, index) => (
                                <BlogCard
                                    key={`${post.slug}-${index}`}
                                    title={post.title}
                                    subject={post.subject}
                                    description={post.description}
                                    heroImage={post.heroImage}
                                    readTime={post.readTime}
                                    date={post.date}
                                />
                            ))
                        )}
                    </div>
                </div>

                <section className='w-full my-20'>
                    <h5 className='font-bold text-lg'>
                        {blog.comments.length} COMMENT{blog.comments.length !== 1 ? 'S' : ''}
                    </h5>

                    {blog.comments.map((comment, index) => (
                        <div
                            key={index}
                            className='w-full rounded-lg my-3 flex p-3 lg:p-6 overflow-hidden bg-[#393939] h-32'
                        >
                            <Icon
                                name={comment.profileImage}
                                className='w-12 h-12 rounded-full'
                                png
                            />
                            <div className='w-[75%] flex flex-col mx-4 h-full'>
                                <p className='font-bold'>{comment.name}</p>
                                <p className='text-sm lg:text-[1rem] mt-2'>{comment.comment}</p>
                                {comment.date && (
                                    <time
                                        dateTime={comment.date}
                                        className='text-sm text-gray-400 mt-auto'
                                    >
                                        {new Date(comment.date).toLocaleDateString()}
                                    </time>
                                )}
                            </div>
                        </div>
                    ))}

                    <form
                        onSubmit={handleCommentSubmit}
                        className='flex lg:flex-row flex-col w-full mt-16 gap-8 h-14'
                    >
                        <input
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder='Put in your comments and feedback'
                            className='bg-white text-black lg:w-[100%] text-left text-lg p-3 rounded-md'
                            required
                            minLength={2}
                            maxLength={500}
                        />
                        <button
                            type="submit"
                            disabled={submitting || !comment.trim()}
                            className='text-white flex items-center justify-center border-2
                                border-[#3588D6] lg:p-5 p-4 rounded-md w-[100%] lg:w-[40%]
                                font-bold disabled:opacity-50 disabled:cursor-not-allowed'
                        >
                            {submitting ? 'SUBMITTING...' : 'LEAVE A COMMENT'}
                        </button>
                    </form>
                </section>
            </article>
            <Newsletter />
        </section>
    );
};

export default BlogDetails;