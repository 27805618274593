import React from 'react'
import SEO from '../components/common/SEO'

const Cookies = () => {
    return (
        <>
        <SEO 
        title="Cookies Policy | Meliorist Developers" 
        description="Learn how Meliorist Developers uses cookies to enhance your browsing experience. Review our cookie practices and manage your preferences." 
        url="/cookies"
        />
        <main>
            <section className="min-h-[100vh] w-full flex flex-col items-center my-10">
                <h1 className="font-extrabold text-4xl lg:text-5xl">Cookies Policy</h1>
                <p className="w-[80%] lg:w-[60%] text-justify mt-10">
                    This Cookies Policy explains how Meliorist Developers ("we," "our," "us") uses cookies and similar technologies to improve your 
                    browsing experience, personalize content, and analyze our website's performance. By using our website, you consent to the use of 
                    cookies as outlined in this policy.
                </p>
                <div className="flex flex-col space-y-5 w-[90%] lg:w-[80%] mt-14 text-start">
                    <h2 className="font-bold text-lg">1. What are Cookies?</h2>
                    <p>
                        Cookies are small text files stored on your device when you visit a website. They help us recognize your device, remember your 
                        preferences, and enhance your overall user experience.
                    </p>

                    <h2 className="font-bold text-lg">2. Types of Cookies We Use</h2>
                    <p>
                        We use the following types of cookies:
                        <ul className="list-disc mx-8 mt-2">
                            <li>
                                <strong>Essential Cookies:</strong> These are necessary for the website to function properly. They enable core functionalities 
                                like security and accessibility.
                            </li>
                            <li>
                                <strong>Performance Cookies:</strong> These cookies collect data on how you use the website to help us improve its performance.
                            </li>
                            <li>
                                <strong>Functional Cookies:</strong> These allow us to remember your preferences, such as language settings.
                            </li>
                            <li>
                                <strong>Targeting/Advertising Cookies:</strong> These are used to deliver content and ads relevant to your interests.
                            </li>
                        </ul>
                    </p>

                    <h2 className="font-bold text-lg">3. Managing Cookies</h2>
                    <p>
                        You can manage or disable cookies through your browser settings. Please note that disabling certain cookies may affect the 
                        functionality of the website.
                    </p>

                    <h2 className="font-bold text-lg">4. Third-Party Cookies</h2>
                    <p>
                        We may also use third-party cookies from trusted partners to analyze website usage and deliver personalized advertisements. 
                        These third parties are responsible for their cookies and privacy practices.
                    </p>

                    <h2 className="font-bold text-lg">5. Updates to this Policy</h2>
                    <p>
                        We may update this Cookies Policy from time to time to reflect changes in technology, laws, or business practices. Please 
                        revisit this page periodically to stay informed.
                    </p>

                    <h2 className="font-bold text-lg">6. Contact Us</h2>
                    <p>
                        If you have any questions about this Cookies Policy, please contact us at{' '}
                        <a href="mailto:core-team@meliorist.dev" className="underline">
                            core-team@meliorist.dev
                        </a>
                        .
                    </p>
                </div>
            </section>
        </main>
        </>
    )
}

export default Cookies