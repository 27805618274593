import React from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

const points = [
  <>
    A chance at <span className="text-primary">partnering with co-founder</span>{" "}
    who's in it for the long haul
  </>,
  <>
    A <span className="text-primary"> team of founders </span> who have your
    back, without being on the payroll all the time
  </>,
  <>
    A group of <span className="text-primary">developers</span> who will bring
    your product to life
  </>,
  <>
    A <span className="text-primary">value-focused</span> mindset that helps you
    create an epic startup, not just a high valuation
  </>,
]

const WhyUs = () => {
  return (
    <Section>
      <div className="flex flex-col lg:flex-row w-full rounded-3xl items-end lg:px-28 gap-10">
        <div className="w-full lg:w-1/2">
          <Icon name="why" png />
        </div>
        <div className="w-full lg:w-1/2">
          <h2 className="text-4xl lg:text-5xl font-bold mb-8">Why us?</h2>
          <h4 className="font-bold text-2xl mb-8">
            What we bring to the table:
          </h4>
          <div className="flex flex-col gap-5 pb-4">
            {points.map((point, i) => (
              <div key={i} className="flex gap-5 font-medium">
                <div className="w-10 h-10 flex justify-center items-center text-2xl rounded-full bg-[#7CA4A9] text-black flex-none">
                  {i + 1}
                </div>
                <div className="text-lg text-gray-300">{point}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhyUs
