import React from "react"

const Section = ({ title, desc, children, className = "", sx = {}, id }) => {
  return (
    <div
      style={sx}
      id={id}
      className={`items-center min-h-screen gap-5 py-14 px-5 lg:px-36 mb-20 ${className}`}
    >
      {title && (
        <h2 className="text-4xl lg:text-5xl font-bold text-center mb-7">
          {title}
        </h2>
      )}
      {desc && (
        <p className="lg:w-2/3 text-center text-base mb-10 text-[#CDCDCD] mx-auto">
          {desc}
        </p>
      )}

      {children}
    </div>
  )
}

export default Section
