import React from "react"

const blobs = [
  <div
    key={0}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "420px",
      top: "173px",
      background:
        "radial-gradient(33.12% 33.12% at 50% 50%, rgba(214, 53, 159, 0.136) 0%, rgba(217, 217, 217, 0) 100%) ",
    }}
  ></div>,
  <div
    key={1}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "-30px",
      top: "716px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={2}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "-93px",
      top: "-93px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,

  <div
    key={3}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "805px",
      top: "1795px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={4}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "-67px",
      top: "2509px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={5}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "857px",
      top: "3309px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={6}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "386px",
      top: "3630px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={7}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "-154px",
      top: "4274px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={8}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "891px",
      top: "5006px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={9}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "-22px",
      top: "6239px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={10}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "874px",
      top: "5868px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  //   <div
  //     key={11}
  //     style={{
  //       position: "absolute",
  //       width: "714px",
  //       height: "714px",
  //       left: "865px",
  //       top: "8567px",
  //       background:
  //         "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
  //     }}
  //   ></div>,
  //   <div
  //     key={12}
  //     style={{
  //       position: "absolute",
  //       width: "714px",
  //       height: "714px",
  //       left: "62px",
  //       top: "8025px",
  //       background:
  //         "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
  //     }}
  //   ></div>,
  <div
    key={13}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "426px",
      top: "7604px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  <div
    key={14}
    style={{
      zIndex: -1,
      position: "absolute",
      width: "714px",
      height: "714px",
      left: "700px",
      top: "7030px",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(53, 136, 214, 0.136) 0%, rgba(217, 217, 217, 0) 100%)",
    }}
  ></div>,
  //   <div style={{}}></div>,
  //   <div style={{}}></div>,
  //   <div style={{}}></div>,
  //   <div style={{}}></div>,
  //   <div style={{}}></div>,
  //   <div style={{}}></div>,
  //   <div style={{}}></div>,
]

export default blobs
