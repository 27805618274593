import React from 'react'
import SEO from '../components/common/SEO'

const Privacy = () => {
    return (
        <>
        <SEO 
        title="Privacy Policy | Meliorist Developers" 
        description="Learn how Meliorist Developers protects your data and privacy. Review our policies on data collection, usage, and security." 
        url="/privacy"
        />
        <main>
            <section className="min-h-[100vh] w-full flex flex-col items-center my-10">
                <h1 className="font-extrabold text-4xl lg:text-5xl">Privacy Policy</h1>
                <p className="w-[80%] lg:w-[60%] text-justify mt-10">
                    When you use our services, you trust us with your information. We take this responsibility seriously and are committed to protecting your data and maintaining transparency about how we handle it.
                </p>
                <div className="flex flex-col space-y-5 w-[90%] lg:w-[80%] mt-14 text-start">
                    <h2 className="font-bold text-lg">Information We Collect and Why</h2>
                    <p>
                        We collect the following types of information to provide and enhance our services:
                        <ol className="list-decimal mx-8">
                            <li>Personal data like name, address, email, and payment details when you create an account or purchase services.</li>
                            <li>Information you provide when contacting our support team or participating in surveys or promotions.</li>
                            <li>Data collected automatically, such as browser type, IP address, and device information, to ensure service optimization.</li>
                            <li>Supplemented data from public databases or third-party sources for accuracy and service enhancement.</li>
                        </ol>
                    </p>
                    <h2 className="font-bold text-lg">How We Use Your Information</h2>
                    <p>
                        We use your information to:
                        <ul className="list-disc mx-8">
                            <li>Provide, maintain, and improve our services.</li>
                            <li>Personalize your experience and deliver targeted ads (only where consented).</li>
                            <li>Communicate updates, offers, or service-related information.</li>
                            <li>Ensure compliance with legal obligations and protect against fraud.</li>
                        </ul>
                    </p>
                    <h2 className="font-bold text-lg">Sharing Your Information</h2>
                    <p>
                        We share information only with trusted third parties or as required by law. These include:
                        <ul className="list-disc mx-8">
                            <li>Payment processors, advertising partners, and analytics providers.</li>
                            <li>Legal or regulatory bodies to comply with legal requirements.</li>
                            <li>Third parties in the event of a business merger or acquisition.</li>
                        </ul>
                    </p>
                    <h2 className="font-bold text-lg">Data Security and Retention</h2>
                    <p>
                        Your data is stored securely using encryption and other safeguards. We retain data only for as long as necessary for the purposes described in this policy or as required by law.
                    </p>
                    <h2 className="font-bold text-lg">Your Rights and Preferences</h2>
                    <p>
                        You can manage your preferences for data collection and communication in your account settings. For further assistance or to request data deletion, contact us at{' '}
                        <a href="mailto:core-team@meliorist.dev" className="underline">
                            core-team@meliorist.dev
                        </a>
                        .
                    </p>
                    <h2 className="font-bold text-lg">Contact Us</h2>
                    <p>
                        If you have any questions or concerns about this Privacy Policy, please reach out to us at{' '}
                        <a href="mailto:core-team@meliorist.dev" className="underline">
                            core-team@meliorist.dev
                        </a>
                        .
                    </p>
                </div>
            </section>
        </main>
        </>
    )
}

export default Privacy
