import React from 'react'
import SEO from '../components/common/SEO'

const Terms = () => {
    return (
        <>
        <SEO 
        title="Terms and Conditions | Meliorist Developers" 
        description="Understand the terms and conditions of using Meliorist Developers' services. Review your rights, responsibilities, and our policies." 
        url="/terms"
        />
        <main>
            <section className="min-h-[100vh] w-full flex flex-col items-center my-10">
                <h1 className="font-extrabold text-4xl lg:text-5xl">Terms and Conditions</h1>
                <p className="w-[80%] lg:w-[60%] text-justify mt-10">
                    Welcome to Meliorist Developers! These terms and conditions outline the rules and regulations for using our website and services.
                    By accessing this website, you agree to be bound by the following terms. If you disagree with any part of these terms, please
                    refrain from using our site or services.
                </p>
                <div className="flex flex-col space-y-5 w-[90%] lg:w-[80%] mt-14 text-start">
                    <h2 className="font-bold text-lg">1. Definitions</h2>
                    <p>
                        - <strong>"We," "Our," "Us"</strong> refers to Meliorist Developers.<br />
                        - <strong>"You," "Your"</strong> refers to the user, client, or viewer of this website.
                    </p>

                    <h2 className="font-bold text-lg">2. Use of Services</h2>
                    <p>
                        By using our services, you agree to:
                        <ul className="list-disc mx-8 mt-2">
                            <li>Use the website only for lawful purposes and not in any way that could damage or impair the website.</li>
                            <li>Provide accurate and current information when requested for account creation or service use.</li>
                            <li>Abide by all applicable laws and regulations.</li>
                        </ul>
                    </p>

                    <h2 className="font-bold text-lg">3. Intellectual Property</h2>
                    <p>
                        All content on this site, including text, graphics, logos, and software, is the property of Meliorist Developers or its
                        licensors and is protected by applicable copyright and intellectual property laws.
                    </p>

                    <h2 className="font-bold text-lg">4. Limitation of Liability</h2>
                    <p>
                        Meliorist Developers will not be held liable for any damages arising from the use or inability to use our services, including
                        but not limited to direct, indirect, incidental, or consequential damages.
                    </p>

                    <h2 className="font-bold text-lg">5. Governing Law</h2>
                    <p>
                        These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any
                        disputes will be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].
                    </p>

                    <h2 className="font-bold text-lg">6. Changes to Terms</h2>
                    <p>
                        We reserve the right to update or modify these terms at any time without prior notice. By continuing to use the website after
                        such changes, you agree to be bound by the updated terms.
                    </p>

                    <h2 className="font-bold text-lg">7. Contact Us</h2>
                    <p>
                        If you have any questions about these Terms and Conditions, please contact us at{' '}
                        <a href="mailto:core-team@meliorist.dev" className="underline">
                            core-team@meliorist.dev
                        </a>
                        .
                    </p>
                </div>
            </section>
        </main>
        </>
    )
}

export default Terms