import React from 'react';
import { Helmet } from 'react-helmet-async';

const DEFAULT_TITLE = 'Meliorist Developers | Sophisticated Software Solutions';
const DEFAULT_DESCRIPTION = 'We are a team of passionate software professionals, who design and build sophisticated software solutions to help our clients realize their long-term goals';
const DEFAULT_KEYWORDS = [
  'software development',
  'startup accelerator',
  'made in India',
  'startup India',
  'freelancing',
  'web development',
  'artificial intelligence',
  'AI',
  'machine learning',
  'programming',
  'coding',
  'tech',
  'startups',
  'entrepreneurship',
  'Indian startups',
  'web design',
  'software engineering'
];

const SEO = ({ 
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  keywords = DEFAULT_KEYWORDS,
  image = '/logo.png',
  url,
  type = 'website',
  publishedAt,
  modifiedAt,
  author = 'Meliorist Developers',
  noindex = false
}) => {
  const siteUrl = 'https://www.meliorist.dev';
  const finalUrl = url ? `${siteUrl}${url}` : siteUrl;
  const finalImage = image.startsWith('http') ? image : `${siteUrl}${image}`;
  
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
      <meta name="author" content={author} />
      <link rel="canonical" href={finalUrl} />
      <meta name="robots" content={noindex ? 'noindex, nofollow' : 'index, follow'} />

      {/* Open Graph Meta Tags */}
      <meta property="og:site_name" content="Meliorist Developers" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={finalImage} />
      <meta property="og:url" content={finalUrl} />
      <meta property="og:type" content={type} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@meliorist_dev" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={finalImage} />

      {/* Article Specific Meta Tags */}
      {type === 'article' && (
        <>
          {publishedAt && <meta property="article:published_time" content={publishedAt} />}
          {modifiedAt && <meta property="article:modified_time" content={modifiedAt} />}
          <meta property="article:author" content={author} />
        </>
      )}

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': type === 'article' ? 'Article' : 'Organization',
          name: 'Meliorist Developers',
          description: description,
          url: finalUrl,
          logo: `${siteUrl}/logo.png`,
          image: finalImage,
          ...(type === 'article' && {
            datePublished: publishedAt,
            dateModified: modifiedAt || publishedAt,
            author: {
              '@type': 'Person',
              name: author
            },
            publisher: {
              '@type': 'Organization',
              name: 'Meliorist Developers',
              logo: {
                '@type': 'ImageObject',
                url: `${siteUrl}/logo.png`
              }
            }
          })
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
